import { mapActions, mapGetters, mapMutations } from "vuex";
import { v4 as uuidv4 } from "uuid";

export const APP_MODULE_NAME = "AppModule";

const initState = {
  browser_id: null,
  dark_mode: false,
  current_theme: null,
  preferred_theme: null
};

const AppModule = {
  namespaced: true,
  state: () => initState,
  getters: {
    browserId: (state) => state.browser_id,
    dark_mode: (s) => s.dark_mode,
    current_theme: (s) => s.current_theme,
    preferred_theme: (s) => s.preferred_theme
  },
  mutations: {
    create: (state) => {
      if (localStorage.getItem("browser_id")) {
        state.browser_id = localStorage.getItem("browser_id");
        return;
      }

      state.browser_id = uuidv4();
      localStorage.setItem("browser_id", state.browser_id);
    },
    set_dark_mode: (state, dark = true) => {
      console.log("Set Dark Mode");
      state.dark_mode = dark;

      const theme_node = document.querySelector("[data-theme]");
      const mode_name = state.dark_mode ? "dark" : "light";
      theme_node.setAttribute("data-theme", mode_name);

      localStorage.setItem("color-mode", mode_name);
    },
    set_theme: (state, theme = "system") => {
      console.log("Theme to set : ", theme);
      state.current_theme = theme;

      if (theme === "system") {
        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        if (darkThemeMq.matches) state.current_theme = "dark";
        else state.current_theme = "light";
      }

      const theme_node = document.querySelector("[data-theme]");
      theme_node.setAttribute("data-theme", state.current_theme);

      localStorage.setItem("color-mode", state.current_theme);
    },
    set_preferred_theme: (state, preferred_theme) => {
      state.preferred_theme = preferred_theme;
    }
  },
  actions: {
    setPreferredTheme({ commit }, theme) {
      commit('set_preferred_theme', theme);
    }
  }
};

export const mapAppActions = (data) => mapActions(APP_MODULE_NAME, data);
export const mapAppGetters = (data) => mapGetters(APP_MODULE_NAME, data);

export const mapAppMutations = (data) => mapMutations(APP_MODULE_NAME, data);

export default AppModule;
